.home {
    --sinodun-blue: #5894b0;
    --sinodun-brown: #b07458;
    --sinodun-purple: #A058B0;
    --sinodun-green: #68B058;
    --sinodun-blue-rgb: 88, 148, 176;
    --sinodun-brown-rgb:  176, 116, 88;
    --sinodun-purple-rgb: 160, 88, 176;
    --sinodun-green-rgb: 104, 176, 88

    .bd-masthead {
        padding: 3rem 0;
        background-image: linear-gradient(180deg, rgba(var(--bs-body-bg-rgb), 0.01), rgba(var(--bs-body-bg-rgb), 1) 85%),radial-gradient(ellipse at top left, rgba(var(--sinodun-blue-rgb), 0.5), transparent 50%),radial-gradient(ellipse at top right, rgba(var(--sinodun-brown-rgb), 0.5), transparent 50%),radial-gradient(ellipse at center right, rgba(var(--sinodun-brown-rgb), 0.5), transparent 50%),radial-gradient(ellipse at center left, rgba(var(--sinodun-purple-rgb), 0.5), transparent 50%);
    }

    .btn-bd-primary {
        --bs-btn-font-weight: 600;
        --bs-btn-color: var(--bs-white);
        --bs-btn-bg: var(--sinodun-blue);
        --bs-btn-border-color: var(--sinodun-blue);
        --bs-btn-border-radius: .5rem;
        --bs-btn-hover-color: var(--bs-white);
        --bs-btn-hover-bg: var(--sinodun-brown);
        --bs-btn-hover-border-color: var(--sinodun-blue);
        --bs-btn-focus-shadow-rgb: var(--sinodun-blue-rgb);
        --bs-btn-active-color: var(--bs-btn-hover-color);
        --bs-btn-active-bg: var(--sinodun-blue);
        --bs-btn-active-border-color: var(--sinodun-blue);
    }
}

.navbar {
    @extend .navbar-dark;

    --bd-sinodun-rgb: 88 ,148 ,176;
    
    padding: .75rem 0;
    background-color: transparent;
    background-image: linear-gradient(to bottom, rgba(var(--bd-sinodun-rgb), 1), rgba(var(--bd-sinodun-rgb), 0.95));
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15),inset 0 -1px 0 rgba(0,0,0,0.15);
}

.text-sinodun {
    color: #6528e0;
}

.list {
    .card {
        transition: transform 0.2s ease-in-out;

        &:hover {
            transform: scale(1.01);
        }
    }
}

